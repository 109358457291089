import React, { useEffect, useRef } from 'react';
import { useAtom } from 'jotai/index';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../css/index.module.scss';
import PageTitle from '../../../components/PageTitle';
import MyButton from '../../../components/MyButton';
import MyInput from '../../../components/MyInput';
import {
  closeModalAtom, modalStateAtom, openConfirmModalAtom, openInfoModalAtom, openLoadingModalAtom,
} from '../../../atoms/globalAtoms';
import {
  addressesInfoAtom,
  addressSaveAtom, isPartnersInputsDirtyAtom,
  partnerSaveAtom,
  partnersFiltersAtom,
  partnersInfoAtom, partnersPageStateAtom,
  selectedPartnerAtom,
} from '../../../atoms/partnersAtoms';
import { PARTNERS_PAGE_STATES } from '../../../constants/statuses';
import SinglePartnerTabs from './SinglePartnerTabs';

function SinglePartnerHeader() {
  const [partnersInfo] = useAtom(partnersInfoAtom);
  const [addressesInfo] = useAtom(addressesInfoAtom);
  const [selectedPartner] = useAtom(selectedPartnerAtom);
  const [partnersPageState] = useAtom(partnersPageStateAtom);
  const [, setPartnerSave] = useAtom(partnerSaveAtom);
  const [, setAddressSave] = useAtom(addressSaveAtom);
  const [, openInfoModal] = useAtom(openInfoModalAtom);
  const [, openConfirmModal] = useAtom(openConfirmModalAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [isPartnersInputsDirty, setIsPartnersInputsDirty] = useAtom(isPartnersInputsDirtyAtom);

  const [searchParams, setSearchParams] = useSearchParams();

  const onNewAddressClick = () => {
    setSearchParams((prev) => ({ ...Object.fromEntries(prev), selected_address_id: -1 }));
  };

  const onPartnerSaveClick = () => {
    setPartnerSave((prev) => ++prev);
  };

  const onAddressSaveClick = () => {
    setAddressSave((prev) => ++prev);
  };

  const onBackClick = () => {
    let fn;
    if (partnersPageState === PARTNERS_PAGE_STATES.singlePartnerSingleAddress) {
      fn = () => setSearchParams({ selected_partner_id: searchParams.get('selected_partner_id') });
    } else {
      fn = () => setSearchParams({});
    }

    if ([
      PARTNERS_PAGE_STATES.singlePartnerInfo,
      PARTNERS_PAGE_STATES.singlePartnerSingleAddress,
    ].includes(partnersPageState) && isPartnersInputsDirty) {
      openConfirmModal({
        title: 'Перейти без сохранения?',
        text: 'Возможно, у вас есть несохранённые изменения, при переходе они будут потеряны',
        buttonText: 'Перейти',
        onButtonClick: () => {
          closeModal();
          fn();
          setIsPartnersInputsDirty(true);
        },
      });
    } else {
      fn();
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.headerLeftTop}>
          <PageTitle text={selectedPartner?.info.name || 'Новый партнер'} />
        </div>
        <div className={styles.headerLeftBottom}>
          {selectedPartner && <SinglePartnerTabs />}
        </div>
      </div>
      <div className={styles.headerRight}>
        <div className={styles.headerRightTop}>
          <MyButton
            text="Назад"
            onClick={onBackClick}
            secondary
          />
          {partnersPageState === PARTNERS_PAGE_STATES.singlePartnerInfo ? (
            <MyButton
              text="Сохранить"
              onClick={onPartnerSaveClick}
              className={styles.mainButton}
            />
          ) : partnersPageState === PARTNERS_PAGE_STATES.singlePartnerAddresses ? (
            <MyButton
              text="Новый адрес"
              onClick={onNewAddressClick}
              className={styles.mainButton}
            />
          ) : partnersPageState === PARTNERS_PAGE_STATES.singlePartnerSingleAddress ? (
            <MyButton
              text="Сохранить"
              onClick={onAddressSaveClick}
              className={styles.mainButton}
            />
          ) : null}
        </div>
        <div className={styles.headerRightBottom}>
          {partnersPageState === PARTNERS_PAGE_STATES.partnersList ? (
            <div className={styles.headerCount}>
              <span className={styles.headerCountLight}>Всего партнеров:</span>
              <span className={styles.headerCountBold}>{partnersInfo.pages?.total_records || 0}</span>
            </div>
          ) : partnersPageState === PARTNERS_PAGE_STATES.singlePartnerAddresses ? (
            <div className={styles.headerCount}>
              <span className={styles.headerCountLight}>Всего адресов:</span>
              <span className={styles.headerCountBold}>{addressesInfo.pages?.total_records || 0}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SinglePartnerHeader;
