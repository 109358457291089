import React, { useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../components/PageTitle';
import styles from './css/index.module.scss';
import MyButton from '../../components/MyButton';
import FileIcon from '../../assets/formIcons/file.svg';
import MyInput from '../../components/MyInput';
import { INTEGRATION_TEXT } from '../../constants/integration';
import useInputState from '../../hooks/UseInputState';
import Dropdown from '../../components/Dropdown';
import AttributesTable from './components/AttributesTable';
import EmptyHeight from '../../components/EmptyHeight';
import IconButton from '../../components/IconButton';
import sleep from '../../helpers/sleep';
import ClipboardCopiedIcon from '../../assets/icons/clipboard_copied.svg';

function Integration(props) {
  const [inputVal, setInputVal] = useInputState(INTEGRATION_TEXT);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const onClipboardClick = async () => {
    setIsCopied(true);
    await navigator.clipboard.writeText(INTEGRATION_TEXT);
    await sleep(1000);
    setIsCopied(false);
  };

  return (
    <div className={styles.integration}>
      <PageTitle text="Интеграция формы онлайн бронирования" />
      <div className={styles.integrationContent}>
        <div className={styles.integrationContentText}>
          Интеграция формы онлайн бронирования
        </div>
        <div className={styles.integrationContentTextGray}>
          Изменять атрибуты кнопки можно стандартными методами CSS
        </div>

        {/* <button
          type="button"
          id="reservista-button"
          data-restaurant-id="1"
          data-manager-phone="+719091112222"
            // data-reserve-date="2038-01-18"
          data-reserve-guests="3"
          data-user-name="Иван"
          data-user-phone="+719091111111"
          data-additional-info="Текст"
            // data-color-main="#ff5375"
            // data-color-background="#ffffff"
            // data-color-secondary="#ffEAFB"
            // data-color-third="#ffd9fa"
            // data-color-text="#ff0347"
            // data-color-button="#ff0479"
          data-interface-language="en"
          data-interface-rounding="false"
        >
          Забронировать стол
        </button>

        <EmptyHeight height={30} /> */}

        <button
          type="button"
          id="reservista-button"
          tabIndex="0"
            // data-color-main="#ff5375"
            // data-color-background="#ffffff"
            // data-color-secondary="#ffEAFB"
            // data-color-third="#ffd9fa"
            // data-color-text="#ff0347"
            // data-color-button="#ff0479"
        >
          <span data-restaurant-id="1" data-manager-phone="+719091111222" hidden>Ресторан №1</span>
          <span data-restaurant-id="2" data-manager-phone="+719091111222" hidden>Ресторан №2</span>
          <span data-restaurant-id="3" data-manager-phone="+719091111222" hidden>Ресторан №3</span>
          <span data-restaurant-id="4" data-manager-phone="+719091111222" hidden>Ресторан №4</span>
          <span data-restaurant-id="5" data-manager-phone="+719091111222" hidden>Ресторан №5</span>
          <span data-restaurant-id="6" data-manager-phone="+719091111222" hidden>Ресторан №6</span>
          <span data-restaurant-id="7" data-manager-phone="+719091111222" hidden>Ресторан №7</span>
          <span data-restaurant-id="8" data-manager-phone="+719091111222" hidden>Ресторан №8</span>
          <span data-restaurant-id="9" data-manager-phone="+719091111222" hidden>Ресторан №9</span>
          <span data-restaurant-id="10" data-manager-phone="+719091111222" hidden>Ресторан №10</span>
          <span data-restaurant-id="11" data-manager-phone="+719091111222" hidden>Ресторан №11</span>
          <span data-restaurant-id="12" data-manager-phone="+719091111222" hidden>Ресторан №12</span>
          <span data-restaurant-id="13" data-manager-phone="+719091111222" hidden>Ресторан №13</span>
          Забронировать
        </button>

        <EmptyHeight height={30} />

        <div className={styles.integrationContentTextIcon}>
          <IconButton
            wrapperClassName={styles.copyButton}
            svgPath={isCopied ? ClipboardCopiedIcon : FileIcon}
            onClick={onClipboardClick}
          />
          Html-код для встраивания кнопки и формы онлайн бронирования на ваш сайт
        </div>
        <MyInput
          value={inputVal}
          onChange={setInputVal}
          isLarge
          withLeftIcon={false}
          withCloseIcon={false}
          className={styles.integrationInput}
          wrapperClassName={styles.integrationInputWrapper}
        />

        <div className={styles.integrationDropdown}>
          <Dropdown
            text="Описание HTML-атрибутов кнопки"
            height={0}
            onClick={setIsDropdownOpen}
          />

          <div className={classNames(styles.attributes, { [styles.attributesOpen]: isDropdownOpen })}>
            <div className={styles.attributesInner}>
              <AttributesTable />
            </div>
          </div>
        </div>

        <EmptyHeight height={100} />
      </div>
    </div>
  );
}

export default Integration;
