export const RESERVES_CHARTS = {
  colors: {
    pink: '#FC58E1',
    blue: '#729FF6',
    green: '#29FA07',
    yellow: '#FEDB20',

    status1: '#B8BEC6',
    status2: '#1192E8',
    status3: '#EDBF92',
    status4: '#FA5050',
    status5: '#BE0000',
    status6: '#00AD11',
  },
  points: {
    first: [
      { name: 'Резервы', color: '#FC58E1' },
      { name: 'Гости', color: '#729FF6' },
    ],
    second: [
      { name: 'Ожидание', color: '#B8BEC6' },
      { name: 'Пришёл', color: '#1192E8' },
      // { name: 'Опаздывает', color: '#EDBF92' },
      { name: 'Не пришёл', color: '#FA5050' },
      { name: 'Отменён хостесом', color: '#BE0000' },
      { name: 'Завершен', color: '#00AD11' },
    ],
    third: [
      { name: 'Резервы', color: '#FC58E1' },
      { name: 'Гости', color: '#729FF6' },
    ],
    fourth: [
      { name: 'Резервы', color: '#FEDB20' },
      { name: 'Пришли', color: '#29FA07' },
    ],
  },
};
