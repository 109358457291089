import React, { useEffect } from 'react';
import { useAtom, useSetAtom } from 'jotai/index';
import { useSearchParams } from 'react-router-dom';
import sendRequest from '../helpers/sendRequest';
import Api from '../api';
import { closeModalAtom, modalStateAtom, openInfoModalAtom } from '../atoms/globalAtoms';
import LoadingModal from '../components/LoadingModal';
import {
  partnersFiltersAtom,
  partnersInfoAtom,
  gettingPartnersLoadingAtom,
  partnersPageStateAtom,
  selectedPartnerIdAtom,
  selectedPartnerAtom,
  addressesInfoAtom,
  addressesFiltersAtom,
  selectedAddressIdAtom, selectedPartnerAddressAtom, deletingAddressLoadingAtom, isPartnersInputsDirtyAtom,
} from '../atoms/partnersAtoms';
import PartnersServices from '../services/PartnersServices';
import { PARTNERS_PAGE_STATES } from '../constants/statuses';
import sleep from '../helpers/sleep';

const UsePartners = () => {
  const [partnersFilters, setPartnersFilters] = useAtom(partnersFiltersAtom);
  const [addressesFilters, setAddressesFilters] = useAtom(addressesFiltersAtom);
  const [partnersInfo, setPartnersInfo] = useAtom(partnersInfoAtom);
  const [addressesInfo, setAddressesInfo] = useAtom(addressesInfoAtom);
  const [gettingPartnersLoading, setGettingPartnersLoading] = useAtom(gettingPartnersLoadingAtom);
  const [, setDeletingAddressLoading] = useAtom(deletingAddressLoadingAtom);
  const [, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [, setPartnersPageState] = useAtom(partnersPageStateAtom);
  const [selectedPartnerId, setSelectedPartnerId] = useAtom(selectedPartnerIdAtom);
  const [selectedAddressId, setSelectedAddressId] = useAtom(selectedAddressIdAtom);
  const [selectedPartner, setSelectedPartner] = useAtom(selectedPartnerAtom);
  const [selectedPartnerAddress, setSelectedPartnerAddress] = useAtom(selectedPartnerAddressAtom);
  const [partnersPageState] = useAtom(partnersPageStateAtom);
  const [isPartnersInputsDirty, setIsPartnersInputsDirty] = useAtom(isPartnersInputsDirtyAtom);
  const [, openInfoModal] = useAtom(openInfoModalAtom);
  const [, setSelectedAddress] = useAtom(selectedPartnerAddressAtom);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPartnerIdQuery = searchParams.get('selected_partner_id');
  const selectedAddressIdQuery = searchParams.get('selected_address_id');
  const isTableViewQuery = searchParams.get('is_table_view') === 'true';

  const getPartners = async () => {
    const payload = PartnersServices.convertTableFilters(partnersFilters);
    const data = await sendRequest({
      request: Api.getPartners,
      payload,
      warnErrorText: 'while getting partners',
      setLoading: setGettingPartnersLoading,
    });

    if (data?.status !== 'ok') return;
    setPartnersInfo(data.data);
  };

  const getAddresses = async () => {
    if (!selectedPartnerId || selectedPartnerId === -1) return;
    const payload = PartnersServices.convertAddressesTableFilters(addressesFilters, selectedPartnerId);
    const data = await sendRequest({
      request: Api.getAddresses,
      payload,
      warnErrorText: 'while getting addresses',
      // setLoading: setGettingPartnersLoading,
    });

    if (data?.status !== 'ok') return;
    setAddressesInfo(data.data);
  };

  const getPartner = async (id) => {
    const data = await sendRequest({
      request: Api.getPartner,
      payload: id,
      warnErrorText: 'while getting partner',
    });

    if (data?.status !== 'ok') return;
    setSelectedPartner(data.data.partner);
  };

  const getAddress = async (id) => {
    const data = await sendRequest({
      request: Api.getAddress,
      payload: { partnerId: selectedPartnerId, id },
      warnErrorText: 'while getting address',
    });

    if (data?.status !== 'ok') return;
    setSelectedPartnerAddress(data.data.address);
  };

  const savePartner = async (payload) => {
    const data = await sendRequest({
      request: Api.savePartner,
      payload,
      warnErrorText: 'while saving partner',
      setLoading: setGettingPartnersLoading,
    });

    if (data?.status !== 'ok') {
      await sleep(200);
      return openInfoModal({ title: 'Ошибка', text: data?.message });
    }
    setSearchParams({ selected_partner_id: data.data.partner.info.id, is_table_view: 'true' });
    setSelectedPartner(data.data.partner);
    setPartnersPageState(PARTNERS_PAGE_STATES.singlePartnerAddresses);
  };

  const saveAddress = async (payload) => {
    const data = await sendRequest({
      request: Api.saveAddress,
      payload,
      warnErrorText: 'while saving address',
      setLoading: setGettingPartnersLoading,
    });

    if (data?.status !== 'ok') {
      await sleep(200);
      return openInfoModal({ title: 'Ошибка', text: data?.message });
    }
    // setPartnersPageState(PARTNERS_PAGE_STATES.singlePartnerAddresses);
    setIsPartnersInputsDirty(false);
    setSelectedAddress(data.data.address);
  };

  const deleteAddress = async (item) => {
    const data = await sendRequest({
      request: Api.deleteAddress,
      payload: { partnerId: selectedPartnerId, addressId: item.id },
      warnErrorText: 'while deleting address',
      setLoading: setDeletingAddressLoading,
    });

    if (data?.status !== 'ok') {
      await sleep(200);
      return openInfoModal({ title: 'Ошибка', text: data?.message });
    }

    setAddressesInfo((prev) => ({ ...prev, addresses: prev.addresses.filter((i) => i.id !== item.id) }));
    closeModal();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getPartners().then();
    }, 500);

    return () => clearTimeout(timer);
  }, [partnersFilters?.date]);

  useEffect(() => {
    if (partnersPageState !== PARTNERS_PAGE_STATES.singlePartnerAddresses) return;
    const timer = setTimeout(() => {
      getAddresses().then();
    }, 500);

    return () => clearTimeout(timer);
  }, [addressesFilters?.date, partnersPageState]);

  useEffect(() => {
    if (!gettingPartnersLoading) return closeModal();

    setModalState({
      isOpen: true,
      closeOnPressOutside: false,
      children: <LoadingModal />,
    });
  }, [gettingPartnersLoading]);

  useEffect(() => {
    if (!selectedPartnerIdQuery) {
      setPartnersPageState(PARTNERS_PAGE_STATES.partnersList);
    } else {
      if (!isTableViewQuery) {
        setPartnersPageState(PARTNERS_PAGE_STATES.singlePartnerInfo);
        setSelectedAddressId(null);
      } else if (!selectedAddressIdQuery) {
        setPartnersPageState(PARTNERS_PAGE_STATES.singlePartnerAddresses);
        setSelectedAddressId(null);
      } else {
        setPartnersPageState(PARTNERS_PAGE_STATES.singlePartnerSingleAddress);
        setSelectedAddressId(+selectedAddressIdQuery);
      }
      setSelectedPartnerId(+selectedPartnerIdQuery);
    }
  }, [selectedPartnerIdQuery, selectedAddressIdQuery, isTableViewQuery]);

  useEffect(() => {
    if (!selectedPartnerId || selectedPartnerId === -1) {
      setAddressesInfo({});
      return setSelectedPartner(null);
    }

    getPartner(selectedPartnerId).then();
  }, [selectedPartnerId, partnersInfo.partners]);

  useEffect(() => {
    if (!selectedAddressId || selectedAddressId === -1) {
      return setSelectedPartnerAddress(null);
    }

    getAddress(selectedAddressId).then();
  }, [selectedAddressId]);

  return { savePartner, saveAddress, deleteAddress };
};

export default UsePartners;
