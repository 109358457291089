import TagsInfoComponent from '../pages/Reserves/components/TagsInfoComponent';

export const RESERVES_HEADERS = {
  id: {
    key: 'id',
    keyForExcel: 'id',
    text: 'ID',
    width: '100px',
  },
  company: {
    key: 'company',
    keyForExcel: 'company.name',
    text: 'Партнер',
    width: '120px',
    forRootOnly: true,
  },
  address: {
    key: 'address',
    keyForExcel: 'address.name',
    text: 'Адрес',
    width: '120px',
    forRootOnly: true,
  },
  created_at: {
    key: 'created_at',
    keyForExcel: 'created_at',
    text: 'Дата создания',
    width: '110px',
  },
  reservation_at: {
    key: 'reservation_at',
    keyForExcel: 'reservation_at',
    text: 'Дата резерва',
    width: '110px',
  },
  status: {
    key: 'status',
    keyForExcel: 'status.name',
    text: 'Статус',
    width: '140px',
  },
  client: {
    key: 'client',
    keyForExcel: 'client.name',
    text: 'Гость',
    width: '192px',
  },
  persons: {
    key: 'persons',
    keyForExcel: 'persons',
    text: 'К-во',
    width: '100px',
  },
  tables: {
    key: 'tables',
    keyForExcel: 'tables',
    text: 'Столы',
    width: '160px',
  },
  sum: {
    key: 'sum',
    keyForExcel: 'sum',
    text: 'Сумма',
    width: '85px',
  },
  tags: {
    key: 'tags',
    keyForExcel: 'tags',
    text: 'Метки',
    width: '460px',
    infoComponent: <TagsInfoComponent />,
  },
  source: {
    key: 'source',
    keyForExcel: 'source',
    text: 'Источник',
    width: '107px',
  },
};

export const CONTACTS_HEADERS = {
  name: {
    key: 'name',
    keyForExcel: 'name',
    text: 'Имя',
    width: '100px',
  },
  phone: {
    key: 'phone',
    keyForExcel: 'phone',
    text: 'Телефон',
    width: '100px',
  },
  reservations: {
    key: 'reservations',
    keyForExcel: 'reservations',
    text: 'Резервы',
    width: '100px',
  },
  visits: {
    key: 'visits',
    keyForExcel: 'visits',
    text: 'Посещения',
    width: '100px',
  },
  labels: {
    key: 'labels',
    keyForExcel: 'labels',
    text: 'Метки гостя',
    width: '100px',
  },
};

export const PARTNERS_HEADERS = {
  id: {
    key: 'id',
    keyForExcel: 'id',
    text: 'ID',
    width: '100px',
  },
  name: {
    key: 'name',
    keyForExcel: 'name',
    text: 'Название',
    width: '100px',
  },
  phone: {
    key: 'phone',
    keyForExcel: 'phone',
    text: 'Номер',
    width: '100px',
  },
  email: {
    key: 'email',
    keyForExcel: 'email',
    text: 'E mail',
    width: '100px',
  },
  active: {
    key: 'active',
    keyForExcel: 'active',
    text: 'Состояние',
    width: '100px',
  },
};

export const ADDRESSES_HEADERS = {
  id: {
    key: 'id',
    keyForExcel: 'id',
    text: 'ID',
    width: '200px',
  },
  name: {
    key: 'name',
    keyForExcel: 'name',
    text: 'Название',
    width: '200px',
  },
  phone: {
    key: 'phone',
    keyForExcel: 'phone',
    text: 'Номер',
    width: '200px',
  },
  active: {
    key: 'active',
    keyForExcel: 'active',
    text: 'Состояние',
    width: '200px',
  },
  plan: {
    key: 'plan',
    keyForExcel: 'plan',
    text: 'План помещений',
    width: '200px',
  },
  delete: {
    key: 'delete',
    keyForExcel: 'delete',
    text: 'Удалить',
    width: '200px',
  },
};
