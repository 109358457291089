export default {
  ru: {
    name: 'RU',
    code: 'ru',
  },
  en: {
    name: 'EN',
    code: 'en',
  },
  am: {
    name: 'AM',
    code: 'am',
  },
  kz: {
    name: 'KZ',
    code: 'kz',
  },
};
