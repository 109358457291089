export const INTEGRATION_TEXT = `<!-- Кнопка с минимальным количеством атрибутов -->
<button type="button"
    id="reservista-button"
    data-restaurant-id="1"
    data-manager-phone="+719091111222"
    >
    Забронировать стол
</button>

<!-- Кнопка с максимальным количеством атрибутов -->
<button type="button"
    id="reservista-button"
    data-restaurant-id="1"
    data-manager-phone="+719091111222"
    data-reservista-date="2038-01-18"
    data-reservista-guests="3"
    data-user-name="Иван"
    data-user-phone="+719091111111"
    data-additional-info="Текст"
    data-color-main="#645375"
    data-color-background="#ffffff"
    data-color-secondary="#F2EAFB"
    data-color-third="#e8d9fa"
    data-color-text="#250347"
    data-color-button="#620479"
    data-interface-language="ru"
    data-interface-rounding="false">
    Забронировать стол
</button>

<!-- Кнопка позволяющая выбрать ресторан -->
<button type="button"
    tabindex="0"
    id="reservista-button">
  <!-- Все атрибуты даются button кроме data-restaurant-id и data-manager-phone -->
  <span data-restaurant-id="1" data-manager-phone="+719091111222" hidden>Ресторан №1</span>
  <span data-restaurant-id="2" data-manager-phone="+719091111222" hidden>Ресторан №2</span>
  Забронировать
</button>

<!-- Обязательный скрипт для вызова формы бронирования -->
<script async defer src="https://reservista.ru/widget/index.js"></script>`;

export const ATTRIBUTES_TABLE_HEADERS = ['Ключ', 'Возможные значения', 'Отвечает за', 'Значение по умолчанию'];

export const ATTRIBUTES_TABLE_DATA = [
  ['data-restaurant-id*', '48447', 'вывод формы бронирования для конкретного ресторана; берется из API Ресторанов', ''],
  ['data-manager-phone*', '+719091111222', 'номер телефона, по которому можно связаться при большой брони', ''],
  [],
  [],
  [],
  [],
  ['data-reservista-date', '2018-01-01', 'дата, на которую создается резерв; ISO-формат YYYY-MM-DD', 'текущая дата, установленная в операционной системе'],
  ['data-reservista-guests', '4', 'количество гостей, на которое создается резерв; от 1 до 20', '2'],
  ['data-user-name', 'Иван', 'имя пользователя', ''],
  ['data-user-phone', '+719091111111', 'номер телефона пользователя', ''],
  ['data-additional-info', 'Текст...', 'дополнительная информация по бронированию', ''],
  [],
  [],
  [],
  [],
  ['data-color-main', '#645375', 'цвет фона шапки формы бронирования, цвет кнопок с текстом', '#645375'],
  ['data-color-background', '#ffffff', 'цвет фона формы бронирования', '#ffffff'],
  ['data-color-secondary', '#f2eafb', 'цвет кликабельных элементов при наведении', '#f2eafb'],
  ['data-color-third', '#e8d9fa', 'цвет кликабельных элементов при фокусе', '#e8d9fa'],
  ['data-color-text', '#250347', 'цвет текста', '#250347'],
  ['data-color-button', '#620479', 'цвет иконок', '#620479'],
  [],
  [],
  [],
  [],
  ['data-interface-language', 'ru', 'локализация формы бронирования (ru/en)', 'ru'],
  ['data-form-rounding', 'false', 'скругление углов у форм и полей ввода', 'true'],
];
