import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import {
  ru, enGB, hy, kk,
} from 'date-fns/locale';
import moment from 'moment/moment';
import styles from '../css/index.module.scss';
import '../../../components/MyDateInput/css/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import arrowRightViolet from '../../../assets/icons/arrowRightViolet.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import { DATE_FORMAT } from '../../../constants';
import makeFirstUppercase from '../../../helpers/makeFirstUppercase';

const lang = {
  ru, en: enGB, am: hy, kz: kk,
};

function WidgetDateInput({
  value, onChange, locale,
}) {
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      dateFormat="EEEE, dd MMMM"
      customInput={<CustomInput />}
      locale={lang[locale] || lang.ru}
      renderCustomHeader={CustomHeader}
      minDate={new Date()}
      calendarClassName={styles.widgetDateInput}
      // popperClassName={styles.widgetDateInput}
    />
  );
}

const CustomInput = React.forwardRef(({
  value, onClick, onChange,
}, ref) => (
  <div className={classNames(styles.row, styles.clickableOpacity, styles.dateInputRow)} onClick={onClick} ref={ref}>
    <p className={styles.text}>{value?.length ? makeFirstUppercase(value) : ''}</p>
    <img className={styles.inputImg} src={calendarIcon} alt="" />
  </div>
));

function CustomHeader({ date, decreaseMonth, increaseMonth }) {
  const convertDate = (d) => {
    if (!d) return '';
    // const convertedDate = new Date(moment(d, DATE_FORMAT)).toLocaleString('ru-RU', { month: 'long', year: 'numeric' });
    const convertedDate = moment(d, DATE_FORMAT).format('MMMM YYYY');
    return convertedDate[0].toUpperCase() + convertedDate.slice(1);
  };

  return (
    <div className={styles.customCalendarHeader}>
      <span className={styles.headerText}>
        {convertDate(date)}
      </span>
      <div className={styles.headerArrows}>
        <div className={classNames(styles.headerArrow, styles.headerArrowLeft)} onClick={decreaseMonth}>
          <img src={arrowRightViolet} alt="<" />
        </div>
        <div className={styles.headerArrow} onClick={increaseMonth}>
          <img src={arrowRightViolet} alt=">" />
        </div>
      </div>
    </div>
  );
}

export default WidgetDateInput;
