import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';
import 'moment/locale/hy-am';
import 'moment/locale/kk';
import styles from './css/index.module.scss';
import useWidget from '../../hooks/UseWidget';
import {
  widgetDataAtom,
  widgetParamsAtom,
  widgetScreenStateAtom,
  widgetSelectedLanguageAtom,
} from '../../atoms/widgetAtoms';
import WidgetHeader from './components/WidgetHeader';
import MyModal from '../../components/MyModal';
import widgetStates from './constants/widgetStates';
import WidgetSlotsState from './components/WidgetSlotsState';
import WidgetInputsState from './components/WidgetInputsState';
import WidgetSuccessState from './components/WidgetSuccessState';

function Widget() {
  const {
    initWidgetData, getSlots, requestToCloseWidget,
  } = useWidget();
  const [searchParams] = useSearchParams();

  const [widgetData] = useAtom(widgetDataAtom);
  const [widgetParams] = useAtom(widgetParamsAtom);
  const [widgetScreenState] = useAtom(widgetScreenStateAtom);
  const [widgetSelectedLanguage] = useAtom(widgetSelectedLanguageAtom);

  useEffect(() => {
    initWidgetData();
  }, []);

  useEffect(() => {
    if (!widgetData?.id) return;

    const timer = setTimeout(() => {
      getSlots().then();
    }, 400);

    return () => clearTimeout(timer);
  }, [widgetParams, widgetData?.id, widgetSelectedLanguage]);

  useEffect(() => {
    if (widgetSelectedLanguage.code === 'am') {
      moment.locale('hy-am');
    } else if (widgetSelectedLanguage.code === 'kz') {
      moment.locale('kk');
    } else if (widgetSelectedLanguage.code === 'en') {
      moment.locale('en');
    } else {
      moment.locale('ru');
    }
  }, [widgetSelectedLanguage]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.action === 'close') {
        requestToCloseWidget();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [requestToCloseWidget]);

  return (
    <div className={styles.wrapper}>
      <WidgetHeader requestToCloseWidget={requestToCloseWidget} />

      <div className={styles.container}>

        {widgetScreenState === widgetStates.slots
          ? <WidgetSlotsState />
          : widgetScreenState === widgetStates.inputs
            ? <WidgetInputsState />
            : widgetScreenState === widgetStates.success
              ? <WidgetSuccessState />
              : null}
      </div>

      <MyModal />
    </div>
  );
}

export default Widget;
