import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import styles from './css/index.module.scss';
import MyButton from '../../components/MyButton';
import FormInput from '../../components/FormInput';
import Email from '../../assets/sidebarIcons/email.svg';
import EmailBlue from '../../assets/sidebarIcons/emailBlue.svg';
import EmailRed from '../../assets/sidebarIcons/emailRed.svg';
import Lock from '../../assets/loginIPageIcons/lock.svg';
import LockBlue from '../../assets/loginIPageIcons/lockBlue.svg';
import LockRed from '../../assets/loginIPageIcons/lockRed.svg';
import LogoIcon from '../../assets/images/logo.png';
import TittleIcon from '../../assets/sidebarIcons/reservista.svg';
import sendRequest from '../../helpers/sendRequest';
import Api from '../../api';
import { tokenAtom } from '../../atoms/globalAtoms';
import LocalStorageServices from '../../services/LocalStorageServices';
import useInputState from '../../hooks/UseInputState';

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail, emailError, setEmailError] = useInputState();
  const [password, setPassword, passwordError, setPasswordError] = useInputState();
  const [loading, setLoading] = useState(false);
  const [, setToken] = useAtom(tokenAtom);

  const onSubmit = async (ev) => {
    ev.preventDefault();
    if (loading) return;
    const data = await sendRequest({
      request: Api.auth,
      payload: { login: email, password },
      warnErrorText: 'while auth',
      setLoading,
    });

    if (data?.status !== 'ok') {
      if (data?.message?.includes('password')) {
        setPasswordError(data.message);
      } else {
        setEmailError(data?.message || 'Неизвестная ошибка');
      }
      return;
    }

    if (data?.data?.token) {
      setToken(data.data.token);
      LocalStorageServices.setToken(data.data.token);
    }
  };

  const onForgotClick = () => {
    navigate('/password-recovery-request');
  };

  return (
    <div className={styles.login_wrapper}>
      <div className={styles.registration_form_div}>
        <form onSubmit={onSubmit}>
          <div className={styles.login_wrapper_logo}>
            <img src={LogoIcon} alt="Reservista" className={styles.login_wrapper_logo_image} />
            <img src={TittleIcon} alt="Reservista" />
          </div>
          <FormInput
            placeholder="E mail"
            autoComplete="on"
            value={email}
            onChange={setEmail}
            error={emailError}
            svgPath={Email}
            svgErrorPath={EmailRed}
            svgActivePath={EmailBlue}
          />
          <FormInput
            placeholder="Пароль"
            autoComplete="on"
            type="password"
            value={password}
            onChange={setPassword}
            error={passwordError}
            svgPath={Lock}
            svgErrorPath={LockRed}
            svgActivePath={LockBlue}
          />
          <MyButton
            onClick={onSubmit}
            type="submit"
            text="Войти"
            className={styles.login_button}
            loading={loading}
          />
          <input type="submit" className={styles.hidden_input} />
          <div onClick={onForgotClick} className={styles.forgotPassword}>Забыли пароль?</div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
