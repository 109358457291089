import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { ru } from 'date-fns/locale';
import moment from 'moment/moment';
import styles from './css/index.module.scss';
import './css/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import MyInput from '../MyInput';
import arrowRightViolet from '../../assets/icons/arrowRightViolet.svg';
import { DATE_FORMAT } from '../../constants';

function MyDatePicker({
  startDate, setStartDate, endDate, setEndDate,
}) {
  const customModifier = () => [
    {
      name: 'myModifier',
      fn(state) {
        state.x += 200 - 60;
        return state;
      },
    },
  ];

  return (
    <div className={styles.row}>
      <DatePicker
        selected={startDate}
        onChange={setStartDate}
        dateFormat="dd.MM.yyyy"
        customInput={<CustomInput />}
        locale={ru}
        renderCustomHeader={CustomHeader}
        popperModifiers={customModifier()}
      />
      <div style={{ width: '16px' }} />
      <DatePicker
        selected={endDate}
        onChange={setEndDate}
        dateFormat="dd.MM.yyyy"
        customInput={<CustomInput />}
        locale={ru}
        renderCustomHeader={CustomHeader}
        popperModifiers={customModifier()}
      />
    </div>
  );
}

const CustomInput = React.forwardRef(({
  value, onClick, onChange, ...p
}, ref) => (
  <div className="custom-date-button" onClick={onClick} ref={ref}>
    <MyInput
      placeholder="01/01/2025"
      value={value}
      withLeftIcon={false}
      withCloseIcon={false}
      className={styles.input}
      wrapperClassName={styles.inputWrapper}
      functionAtCleaning={() => onChange(null)}
    />
  </div>
));

function CustomHeader({ date, decreaseMonth, increaseMonth }) {
  const convertDate = (d) => {
    if (!d) return '';
    const convertedDate = new Date(moment(d, DATE_FORMAT)).toLocaleString('ru-RU', { month: 'long', year: 'numeric' });
    return convertedDate[0].toUpperCase() + convertedDate.slice(1, -2);
  };

  return (
    <div className={styles.customHeader}>
      <span className={styles.headerText}>
        {convertDate(date)}
      </span>
      <div className={styles.headerArrows}>
        <div className={classNames(styles.headerArrow, styles.headerArrowLeft)} onClick={decreaseMonth}>
          <img src={arrowRightViolet} alt="<" />
        </div>
        <div className={styles.headerArrow} onClick={increaseMonth}>
          <img src={arrowRightViolet} alt=">" />
        </div>
      </div>
    </div>
  );
}

export default MyDatePicker;
