import React from 'react';
import { useAtomValue } from 'jotai/index';
import styles from '../css/index.module.scss';
import MyButton from '../../../components/MyButton';
import { deletingAddressLoadingAtom } from '../../../atoms/partnersAtoms';

function DeleteAddressModal({ onDelete, onClose }) {
  const deletingAddressLoading = useAtomValue(deletingAddressLoadingAtom);

  return (
    <div className="modal_wrapper">
      <div className="modal_content">
        <p className="modal_title">Удалить?</p>
        <p className="modal_descr">
          Вы уверены что хотите удалить этот адрес? Отменить данное действие будет
          невозможно.
        </p>
      </div>
      <div className="modal_buttons_row">
        <MyButton text="Отменить" secondary onClick={onClose} disabled={deletingAddressLoading} />
        <MyButton text="Удалить" onClick={onDelete} loading={deletingAddressLoading} />
      </div>
    </div>
  );
}

export default DeleteAddressModal;
