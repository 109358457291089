import moment from 'moment';
import classNames from 'classnames';
import widgetLanguages from '../pages/Widget/constants/widgetLanguages';

class WidgetsServices {
  static sortedWidgetLanguages = (selected, allowedLanguages) => {
    const filteredLanguages = Object.values(widgetLanguages).filter((i) => i.code !== selected.code && allowedLanguages.includes(i.code));
    return [selected, ...filteredLanguages];
  };

  static isValidAndFutureOrToday = (dateString) => {
    const format = 'YYYY-MM-DD';
    const today = moment().startOf('day');

    const isValidFormat = moment(dateString, format, true).isValid();

    if (!isValidFormat) {
      return false;
    }

    const inputDate = moment(dateString, format);
    return inputDate.isSameOrAfter(today);
  };

  static convertPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== 'string') return;
    if (phoneNumber[0] !== '+') phoneNumber = `+${phoneNumber}`;

    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)} ${phoneNumber.slice(5, 8)} ${phoneNumber.slice(8, 10)} ${phoneNumber.slice(10, 12)}`;
  };

  static checkIfColorIsValid = (color) => {
    const hexColorRegex = /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/;
    return hexColorRegex.test(color);
  };

  static shouldShowManagerPhoneComponent = ({
    widgetData, widgetParams, styles, widgetDefaults,
  }) => {
    if (typeof widgetData.max_guests === 'number' && widgetData.max_guests < widgetParams.persons) {
      return (
        <>
          {': '}
          <a
            href={`tel:${'+7 999 123 34 55'}`}
            className={classNames(styles.phoneNumber, styles.clickableOpacity)}
          >
            {widgetDefaults.managerPhone}
          </a>
        </>
      );
    }
    return null;
  };
}

export default WidgetsServices;
