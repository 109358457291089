import React, { useEffect, useRef } from 'react';
import { useAtomValue } from 'jotai/index';
import styles from './css/index.module.scss';
import MyButton from '../../../../components/MyButton';
import MyInput from '../../../../components/MyInput';
import useInputState from '../../../../hooks/UseInputState';
import { savingHallLoadingAtom } from '../../../../atoms/hallsAtoms';

function AddHallModal({ onAdd, onClose }) {
  const inputRef = useRef(null);
  const [inputVal, setInputVal, inputValError, setInputValError] = useInputState();
  const savingHallLoading = useAtomValue(savingHallLoadingAtom);

  const onSave = () => {
    if (!inputVal?.length) return setInputValError('Введите название');
    onAdd(inputVal);
  };

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [inputRef]);

  return (
    <div className="modal_wrapper">
      <div>
        <p className="modal_title">Новое помещение</p>
        <MyInput
          refToPass={inputRef}
          labelTextTop="Название помещения"
          value={inputVal}
          onChange={setInputVal}
          withLeftIcon={false}
          withCloseIcon={false}
          error={inputValError}
          wrapperClassName={styles.modalInput}
        />
      </div>
      <div className="modal_buttons_row">
        <MyButton text="Отменить" secondary onClick={onClose} disabled={savingHallLoading} />
        <MyButton text="Сохранить" onClick={onSave} loading={savingHallLoading} />
      </div>
    </div>
  );
}

export default AddHallModal;
