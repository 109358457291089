import { atom } from 'jotai';

const contactsFiltersAtom = atom({
  // for properly updating
  date: new Date().toISOString(),
}, (get, set, update) => {
  set(contactsFiltersAtom, update?.replace ? {
    ...update,
    replace: undefined,
  } : {
    ...get(contactsFiltersAtom),
    page: 1,
    ...update,

    // for properly updating
    newDate: null,
    date: update.newDate || new Date().toISOString(),
  });
});

const contactsInfoAtom = atom({});
const gettingContactsLoadingAtom = atom(false);

export {
  contactsFiltersAtom,
  contactsInfoAtom,
  gettingContactsLoadingAtom,
};
