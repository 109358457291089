import { useAtom } from 'jotai/index';
import { useEffect } from 'react';
import moment from 'moment';
import useInputState from './UseInputState';
import {
  isPartnersInputsDirtyAtom,
  partnerSaveAtom,
  selectedPartnerAtom,
} from '../atoms/partnersAtoms';
import PartnersServices from '../services/PartnersServices';
import { DATE_FORMAT } from '../constants';
import convertPhoneNumber from '../helpers/convertPhoneNumber';

const UseSinglePartnerInputs = ({ savePartner }) => {
  const [active, setActive, activeError, setActiveError] = useInputState();
  const [name, setName, nameError, setNameError] = useInputState();
  const [descr, setDescr, descrError, setDescrError] = useInputState();
  const [site, setSite, siteError, setSiteError] = useInputState();
  const [info, setInfo, infoError, setInfoError] = useInputState();

  const [inn, setInn, innError, setInnError] = useInputState();
  const [kpp, setKpp, kppError, setKppError] = useInputState();
  const [number, setNumber, numberError, setNumberError] = useInputState();
  const [date, setDate, dateError, setDateError] = useInputState();

  const [fio, setFio, fioError, setFioError] = useInputState();
  const [phone, setPhone, phoneError, setPhoneError] = useInputState();
  const [email, setEmail, emailError, setEmailError] = useInputState();

  const [password, setPassword, passwordError, setPasswordError] = useInputState();
  const [passwordRepeat, setPasswordRepeat, passwordRepeatError, setPasswordRepeatError] = useInputState();

  const [partnerSave, setPartnerSave] = useAtom(partnerSaveAtom);
  const [selectedPartner] = useAtom(selectedPartnerAtom);
  const [, setIsPartnersInputsDirty] = useAtom(isPartnersInputsDirtyAtom);

  const checkForErrors = () => {
    let isError = false;
    if (!active) {
      setActiveError('Укажите статус');
      isError = true;
    }
    if (!name) {
      setNameError('Укажите название');
      isError = true;
    }
    if (!descr) {
      setDescrError('Укажите юридическое название');
      isError = true;
    }
    if (!site) {
      setSiteError('Укажите сайт');
      isError = true;
    }

    if (!inn) {
      setInnError('Укажите ИНН');
      isError = true;
    }
    if (!kpp) {
      setKppError('Укажите КПП');
      isError = true;
    }
    if (!number) {
      setNumberError('Укажите номер договора');
      isError = true;
    }
    if (!date) {
      setDateError('Укажите дату договора');
      isError = true;
    }

    if (!fio) {
      setFioError('Укажите ФИО');
      isError = true;
    }
    if (!phone) {
      setPhoneError('Укажите номер телефона');
      isError = true;
    }
    if (!email) {
      setEmailError('Укажите email');
      isError = true;
    }

    if (!selectedPartner || password?.length) {
      if (password?.length < 8) {
        setPasswordError('Минимум 8 символов');
        isError = true;
      } else if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
        setPasswordError('Минимум 1 буква из верхнего и нижнего регистров');
        isError = true;
      } else if (!/[^a-zA-Z0-9]/.test(password)) {
        setPasswordError('Минимум 1 символ');
        isError = true;
      } else if (!/[0-9]/.test(password)) {
        setPasswordError('Минимум 1 число');
        isError = true;
      }
    }
    if (passwordRepeat !== password) {
      setPasswordRepeatError('Пароли не совпадают');
      isError = true;
    }

    return isError;
  };

  const handleSavePartner = () => {
    const isError = checkForErrors();
    if (isError) return;

    savePartner(PartnersServices.convertSavingPayload({
      id: selectedPartner?.info.id,
      active,
      name,
      descr,
      site,
      info,
      inn,
      kpp,
      number,
      date,
      fio,
      phone,
      email,
      password,
      passwordRepeat,
    }));
  };

  useEffect(() => {
    if (partnerSave) {
      handleSavePartner();
      setPartnerSave(0);
    }
  }, [partnerSave]);

  useEffect(() => {
    if (!selectedPartner) {
      return setIsPartnersInputsDirty(true);
    }

    // checking if inputs were changed
    const newObj = PartnersServices.convertSavingPayload({
      id: selectedPartner?.info.id,
      active,
      name,
      descr,
      site,
      info,
      inn,
      kpp,
      number,
      date,
      fio,
      phone,
      email,
      password,
      passwordRepeat,
    }, true);

    const newSelectedPartner = {
      info: { ...selectedPartner.info, phone: convertPhoneNumber(selectedPartner.info.phone) },
      user: { ...selectedPartner.user },
    };

    // if some state was changed
    setIsPartnersInputsDirty(JSON.stringify(newObj) !== JSON.stringify(newSelectedPartner));
  });

  useEffect(() => {
    if (!selectedPartner) return;
    setActive(selectedPartner.info.active ? { value: 'active', label: 'Активен' } : { value: 'nonActive', label: 'Заблокирован' }, true);
    setName(selectedPartner.info.name || '', true);
    setDescr(selectedPartner.info.descr || '', true);
    setSite(selectedPartner.info.site || '', true);
    setInfo(selectedPartner.info.info || '', true);
    setInn(selectedPartner.info.inn || '', true);
    setKpp(selectedPartner.info.kpp || '', true);
    setNumber(selectedPartner.info.agreement_number || '', true);
    setDate(new Date(moment(selectedPartner.info.agreement_date, DATE_FORMAT)), true);

    setFio(selectedPartner.user?.name || '', true);
    setPhone(selectedPartner.user?.phone || '', true);
    setEmail(selectedPartner.user?.login || '', true);
  }, [selectedPartner]);

  return {
    values: {
      active,
      name,
      descr,
      site,
      info,
      inn,
      kpp,
      number,
      date,
      fio,
      phone,
      email,
      password,
      passwordRepeat,
    },
    setters: {
      active: setActive,
      name: setName,
      descr: setDescr,
      site: setSite,
      info: setInfo,
      inn: setInn,
      kpp: setKpp,
      number: setNumber,
      date: setDate,
      fio: setFio,
      phone: setPhone,
      email: setEmail,
      password: setPassword,
      passwordRepeat: setPasswordRepeat,
    },
    errors: {
      active: activeError,
      name: nameError,
      descr: descrError,
      site: siteError,
      info: infoError,
      inn: innError,
      kpp: kppError,
      number: numberError,
      date: dateError,
      fio: fioError,
      phone: phoneError,
      email: emailError,
      password: passwordError,
      passwordRepeat: passwordRepeatError,
    },
  };
};

export default UseSinglePartnerInputs;
