import React from 'react';
import { useAtom } from 'jotai/index';
import classNames from 'classnames';
import {
  widgetDataAtom, widgetDefaultsAtom,
  widgetInputsAtom,
  widgetScreenStateAtom,
  widgetSelectedLanguageAtom,
} from '../../../atoms/widgetAtoms';
import styles from '../css/index.module.scss';
import useComponentVisible from '../../../hooks/UseComponentVisible';
import widgetLanguages from '../constants/widgetLanguages';
import WidgetsServices from '../../../services/WidgetsServices';
import IconButton from '../../../components/IconButton';
import WidgetArrowIcon from './WidgetArrowIcon';
import widgetStates from '../constants/widgetStates';
import WidgetCloseIcon from './WidgetCloseIcon';

function WidgetHeader({ requestToCloseWidget }) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [widgetData, setWidgetData] = useAtom(widgetDataAtom);
  const [widgetSelectedLanguage, setWidgetSelectedLanguage] = useAtom(widgetSelectedLanguageAtom);
  const [widgetScreenState, setWidgetScreenState] = useAtom(widgetScreenStateAtom);
  const [widgetDefaults] = useAtom(widgetDefaultsAtom);

  const onLanguageSelect = (ev, code) => {
    ev.stopPropagation();
    setWidgetSelectedLanguage(widgetLanguages[code]);
    setIsComponentVisible(false);
  };

  const onBackClick = () => {
    if (widgetScreenState === widgetStates.inputs) {
      setWidgetScreenState(widgetStates.slots);
    }
  };

  const onCloseClick = () => {
    requestToCloseWidget();
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        {widgetScreenState === widgetStates.inputs && (
          <IconButton
            svgPath={WidgetArrowIcon()}
            onClick={onBackClick}
            wrapperClassName={classNames(styles.arrowButton, styles.clickableOpacity)}
            noIconFilter
          />
        )}
        <p className={styles.title}>{widgetData.name}</p>
      </div>

      <div className={styles.headerRight}>
        <div
          className={classNames(styles.languageButton, styles.clickable)}
          onClick={() => setIsComponentVisible(true)}
        >
          {widgetSelectedLanguage.name}
          <div
            className={classNames(styles.languageDropdown, { [styles.languageDropdownVisible]: isComponentVisible })}
            ref={ref}
          >
            {WidgetsServices.sortedWidgetLanguages(widgetSelectedLanguage, widgetDefaults.languagesList).map(({
              code,
              name,
            }) => (
              <div
                key={code}
                className={classNames(styles.languageButton, styles.clickable)}
                onClick={(ev) => onLanguageSelect(ev, code)}
              >
                {name}
              </div>
            ))}
          </div>
        </div>

        <IconButton
          svgPath={WidgetCloseIcon()}
          onClick={onCloseClick}
          wrapperClassName={classNames(styles.arrowButton, styles.clickableOpacity)}
          noIconFilter
        />
      </div>
    </div>
  );
}

export default WidgetHeader;
