import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import styles from './css/index.module.scss';
import MyButton from '../../components/MyButton';
import FormInput from '../../components/FormInput';
import Email from '../../assets/sidebarIcons/email.svg';
import EmailBlue from '../../assets/sidebarIcons/emailBlue.svg';
import EmailRed from '../../assets/sidebarIcons/emailRed.svg';
import Lock from '../../assets/loginIPageIcons/lock.svg';
import LockBlue from '../../assets/loginIPageIcons/lockBlue.svg';
import LockRed from '../../assets/loginIPageIcons/lockRed.svg';
import LogoIcon from '../../assets/images/logo.png';
import TittleIcon from '../../assets/sidebarIcons/reservista.svg';
import sendRequest from '../../helpers/sendRequest';
import Api from '../../api';
import { tokenAtom } from '../../atoms/globalAtoms';
import LocalStorageServices from '../../services/LocalStorageServices';
import useInputState from '../../hooks/UseInputState';

function PasswordRecovery() {
  const navigate = useNavigate();
  const [email, setEmail, emailError, setEmailError] = useInputState();
  const [loading, setLoading] = useState(false);
  const [, setToken] = useAtom(tokenAtom);
  const [isSuccess, setIsSuccess] = useState(false);
  const [serverMessage, setServerMessage] = useState('');

  const onSubmit = async (ev) => {
    ev.preventDefault();
    if (loading) return;
    if (!email?.length) {
      setEmailError('Введите почту');
      return;
    }
    const data = await sendRequest({
      request: Api.recoverPassword,
      payload: { email },
      warnErrorText: 'while recovering password',
      setLoading,
    });

    if (data?.status !== 'ok') {
      setEmailError(data?.message || 'Неизвестная ошибка');
      return;
    }

    setIsSuccess(true);
    if (data?.message) {
      setServerMessage(data.message);
    }
  };

  const onBackClick = () => {
    navigate('/login');
  };

  return (
    <div className={styles.login_wrapper}>
      <div className={styles.registration_form_div}>
        <form onSubmit={onSubmit}>
          <div className={styles.login_wrapper_logo}>
            <img src={LogoIcon} alt="Reservista" className={styles.login_wrapper_logo_image} />
            <img src={TittleIcon} alt="Reservista" />
          </div>
          <p className={styles.login_wrapper_header}>
            {isSuccess && serverMessage ? serverMessage : 'Для восстановления пароля введите адрес электронной почты'}
          </p>
          {!isSuccess && (
          <FormInput
            placeholder="E mail"
            autoComplete="on"
            value={email}
            onChange={setEmail}
            error={emailError}
            svgPath={Email}
            svgErrorPath={EmailRed}
            svgActivePath={EmailBlue}
          />
          )}

          {isSuccess ? (
            <MyButton
              onClick={onBackClick}
              text="Вернуться"
              className={styles.login_button}
              loading={loading}
            />
          ) : (
            <>
              <MyButton
                onClick={onSubmit}
                type="submit"
                text="Отправить"
                className={styles.login_button}
                loading={loading}
              />
              <input type="submit" className={styles.hidden_input} />
              <div onClick={onBackClick} className={styles.forgotPassword}>Назад</div>
            </>
          )}

        </form>
      </div>
    </div>
  );
}

export default PasswordRecovery;
