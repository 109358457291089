import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import moment from 'moment';
import {
  cancelingReservesLoadingAtom,
  gettingReservesLoadingAtom,
  reservesInfoAtom,
  reservesModeAtom,
  reservesTableFiltersAtom,
} from '../atoms/reservesAtoms';
import sendRequest from '../helpers/sendRequest';
import Api from '../api';
import {
  closeModalAtom, isRootAtom, modalStateAtom, openInfoModalAtom,
} from '../atoms/globalAtoms';
import LoadingModal from '../components/LoadingModal';
import ReservesServices from '../services/ReservesServices';
import { DATE_FORMAT } from '../constants';
import DateServices from '../services/DateServices';
import { selectedAddressAtom } from '../atoms/accountAtoms';

const UseReserves = () => {
  const [reservesTableFilters, setReservesTableFilters] = useAtom(reservesTableFiltersAtom);
  const [reservesMode] = useAtom(reservesModeAtom);
  const [, setReservesInfo] = useAtom(reservesInfoAtom);
  const [gettingReservesLoading, setGettingReservesLoading] = useAtom(gettingReservesLoadingAtom);
  const [, setCancelingReservesLoading] = useAtom(cancelingReservesLoadingAtom);
  const [selectedAddress] = useAtom(selectedAddressAtom);
  const [isRoot] = useAtom(isRootAtom);
  const [, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [, openInfoModal] = useAtom(openInfoModalAtom);

  const getReserves = async () => {
    if (!checkFilterDates()) return;
    const payload = ReservesServices.convertTableFilters(reservesTableFilters, reservesMode);
    const data = await sendRequest({
      request: Api.getReserves,
      payload,
      warnErrorText: 'while getting reserves',
      setLoading: setGettingReservesLoading,
    });

    if (data?.status !== 'ok') return;
    setReservesInfo(data.data);

    if (data.data.sort_default) {
      const [key, type] = data.data.sort_default.split('.');
      setReservesTableFilters({
        sortType: type,
        sortKey: key,
        newDate: reservesTableFilters.date,
      });
    }
  };

  const checkFilterDates = () => {
    const startDate = moment(reservesTableFilters.startDate, DATE_FORMAT);
    const endDate = moment(reservesTableFilters.endDate, DATE_FORMAT);
    const diffInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

    if (diffInDays > 365) {
      openInfoModal({ text: 'Нельзя указывать временной период в фильтре больше одного года' });
      return false;
    }
    return true;
  };

  const cancelReserve = async (id) => {
    const data = await sendRequest({
      request: Api.cancelReserve,
      payload: { id },
      warnErrorText: 'while canceling the reserve',
      setLoading: setCancelingReservesLoading,
    });

    if (data?.status !== 'ok') return;

    setReservesInfo((prev) => ({
      ...prev,
      reservations: prev.reservations.map((i) => (
        i.id === data.data.reservation.id
          ? ({ ...i, status: data.data.reservation.status, can_cancel: false })
          : i)),
    }));
    closeModal();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getReserves().then();
    }, 500);

    return () => clearTimeout(timer);
  }, [reservesTableFilters?.date, reservesMode]);

  useEffect(() => {
    if (!gettingReservesLoading) return closeModal();

    setModalState({
      isOpen: true,
      closeOnPressOutside: false,
      children: <LoadingModal />,
    });
  }, [gettingReservesLoading]);

  useEffect(() => {
    setReservesTableFilters({
      startDate: DateServices.getThisMonthFirstDay(),
      endDate: DateServices.getThisMonthLastDay(),
      date: new Date().toISOString(),
      replace: true,
      addresses: isRoot ? undefined : [selectedAddress?.id],
    });
  }, []);

  return { cancelReserve };
};

export default UseReserves;
