import React, { useCallback, useRef } from 'react';
import { useAtom } from 'jotai/index';
import { useNavigate } from 'react-router-dom';
import {
  closeModalAtom, currentPathNameAtom, openConfirmModalAtom, openInfoModalAtom,
} from '../atoms/globalAtoms';
import usePathnameChange from './UsePathnameChange';
import { isPartnersInputsDirtyAtom, partnersPageStateAtom } from '../atoms/partnersAtoms';
import { PARTNERS_PAGE_STATES } from '../constants/statuses';
import { isSelectedHallStateDirtyAtom } from '../atoms/hallsAtoms';

const useNavigationBlocker = () => {
  const navigate = useNavigate();
  const [currentPathName, setCurrentPathName] = useAtom(currentPathNameAtom);
  const [, openInfoModal] = useAtom(openInfoModalAtom);
  const [, openConfirmModal] = useAtom(openConfirmModalAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const currentPathNameRef = useRef(currentPathName);
  const [partnersPageState, setPartnersPageState] = useAtom(partnersPageStateAtom);
  const [isPartnersInputsDirty, setIsPartnersInputsDirty] = useAtom(isPartnersInputsDirtyAtom);
  const [isSelectedHallStateDirty, setIsSelectedHallStateDirty] = useAtom(isSelectedHallStateDirtyAtom);

  const onPathNameChange = useCallback((newPathName) => {
    setCurrentPathName(newPathName);
    currentPathNameRef.current = newPathName;
  }, [setCurrentPathName]);
  usePathnameChange(onPathNameChange);

  const checkNavigation = (oldPathName, newPathName) => {
    if (oldPathName === '/partners') {
      if ([
        PARTNERS_PAGE_STATES.singlePartnerInfo,
        PARTNERS_PAGE_STATES.singlePartnerSingleAddress,
      ].includes(partnersPageState) && isPartnersInputsDirty) {
        return { shouldBlock: true, cbOnConfirm: () => setIsPartnersInputsDirty(false) };
      }
    } else if (oldPathName === '/plan') {
      if (isSelectedHallStateDirty) {
        return { shouldBlock: true, cbOnConfirm: () => setIsSelectedHallStateDirty(false) };
      }
    }
    return { shouldBlock: false };
  };

  const onLinkClick = (newPathName) => {
    const { shouldBlock, cbOnConfirm } = checkNavigation(currentPathName, newPathName);
    if (shouldBlock) {
      openConfirmModal({
        title: 'Перейти без сохранения?',
        text: 'Возможно, у вас есть несохранённые изменения, при переходе они будут потеряны',
        buttonText: 'Перейти',
        onButtonClick: () => {
          closeModal();
          navigate(newPathName);
          if (typeof cbOnConfirm === 'function') cbOnConfirm();
        },
      });
    } else {
      navigate(newPathName);
    }
  };

  // useBlocker(shouldBlock, 'stop');

  return { onLinkClick };
};

export default useNavigationBlocker;
