import { useAtom } from 'jotai/index';
import { useEffect, useState } from 'react';
import moment from 'moment';
import useInputState from './UseInputState';
import {
  addressSaveAtom, isPartnersInputsDirtyAtom,
  selectedPartnerAddressAtom,
  selectedPartnerAtom,
} from '../atoms/partnersAtoms';
import PartnersServices from '../services/PartnersServices';
import deepClone from '../helpers/deepClone';
import { DEFAULT_WEEK_DAYS } from '../constants/addresses';
import sleep from '../helpers/sleep';
import convertPhoneNumber from '../helpers/convertPhoneNumber';
import convertSchedule from '../helpers/convertSchedule';

const UseSingleAddressInputs = ({ saveAddress }) => {
  const [active, setActive, activeError, setActiveError] = useInputState();
  const [name, setName, nameError, setNameError] = useInputState();
  const [address, setAddress, addressError, setAddressError] = useInputState();
  const [phone, setPhone, phoneError, setPhoneError] = useInputState();
  const [hostessLogin, setHostessLogin] = useState('');
  const [hostessLoginCopied, setHostessLoginCopied] = useState(false);

  const [weekDays, setWeekDays] = useState(deepClone(DEFAULT_WEEK_DAYS));
  const [weekDaysError, setWeekDaysError] = useState('');

  const [holidays, setHolidays] = useState([]);
  const [holidayInputs, setHolidayInputs, holidayInputsError, setHolidayInputsError] = useInputState({});

  const [addressSave, setAddressSave] = useAtom(addressSaveAtom);
  const [selectedPartner] = useAtom(selectedPartnerAtom);
  const [selectedAddress] = useAtom(selectedPartnerAddressAtom);
  const [, setIsPartnersInputsDirty] = useAtom(isPartnersInputsDirtyAtom);

  const checkForErrors = () => {
    let isError = false;
    if (!active) {
      setActiveError('Укажите статус');
      isError = true;
    }
    if (!name) {
      setNameError('Укажите название');
      isError = true;
    }
    if (!address) {
      setAddressError('Укажите адрес');
      isError = true;
    }
    if (!phone) {
      setPhoneError('Укажите номер телефона');
      isError = true;
    }

    const times = weekDays.filter((i) => !i.day_off).reduce((acc, i) => [...acc, i.open_time, i.close_time], []);
    // eslint-disable-next-line no-restricted-syntax
    for (const time of times) {
      if (!moment(time, 'HH:mm', true).isValid()) {
        setWeekDaysError('Некоторые поля заполнены неправильно');
        isError = true;
        break;
      }
    }

    return isError;
  };

  const handleSaveAddress = () => {
    const isError = checkForErrors();
    if (isError) return;

    saveAddress(PartnersServices.convertAddressSavingPayload({
      partnerId: selectedPartner.info.id,
      id: selectedAddress?.id,
      active,
      name,
      address,
      phone,
      weekDays,
      holidays,
    }));
  };

  const handleWeekDay = (index, field) => (ev) => {
    weekDays[index][field] = field === 'day_off' ? !weekDays[index][field] : ev.target.value;
    setWeekDays([...weekDays]);
    setWeekDaysError('');
  };
  const handleHolidayInputs = (field) => (ev) => {
    holidayInputs[field] = field === 'day_off' ? !holidayInputs[field] : ev.target.value;
    setHolidayInputs({ ...holidayInputs }, true);
  };

  const onClipboardClick = async () => {
    setHostessLoginCopied(true);
    await navigator.clipboard.writeText(hostessLogin);
    await sleep(1000);
    setHostessLoginCopied(false);
  };

  const onDeleteHolidayClick = (index) => () => {
    const newHolidays = holidays.filter((_, i) => i !== index);
    setHolidays([...newHolidays]);
  };

  const onAddHolidayClick = () => {
    let error = false;
    if (!holidayInputs.date) {
      setHolidayInputsError('Укажите дату');
      error = true;
    } else if (!moment(holidayInputs.date, 'DD.MM.YYYY', true).isValid()) {
      setHolidayInputsError('Невалидная дата');
      error = true;
    } else if (!holidayInputs.day_off) {
      if (!moment(holidayInputs.open_time, 'HH:mm', true).isValid()) {
        setHolidayInputsError('Невалидное время открытия');
        error = true;
      } else if (!moment(holidayInputs.close_time, 'HH:mm', true).isValid()) {
        setHolidayInputsError('Невалидное время закрытия');
        error = true;
      }
    }
    if (error) return;

    const newHolidays = holidays.filter((i) => i.date !== holidayInputs.date);

    newHolidays.unshift({
      // date: PartnersServices.convertDateFormat(holidayInputs.date, 'DD.MM.YYYY', 'YYYY-MM-DD'),
      date: holidayInputs.date,
      open_time: holidayInputs.open_time,
      close_time: holidayInputs.close_time,
      day_off: holidayInputs.day_off,
    });

    setHolidays([...newHolidays]);
    setHolidayInputs({}, true);
  };

  useEffect(() => {
    if (addressSave) {
      handleSaveAddress();
      setAddressSave(0);
    }
  }, [addressSave]);

  useEffect(() => {
    if (!selectedAddress) return;
    setActive(selectedAddress.active ? { value: 'active', label: 'Активен' } : { value: 'nonActive', label: 'Заблокирован' }, true);
    setName(selectedAddress.name || '', true);
    setAddress(selectedAddress.descr || '', true);
    setPhone(selectedAddress.phone || '', true);
    setHostessLogin(selectedAddress.login);

    const selectedAddressWeekDays = selectedAddress.schedule
      .filter((i) => Boolean(i.weekday))
      .map((i) => ({
        ...i,
        open_time: PartnersServices.convertSecondsToFormat(i.open_time),
        close_time: PartnersServices.convertSecondsToFormat(i.close_time),
      }));
    setWeekDays(selectedAddressWeekDays);

    const selectedAddressHolidays = selectedAddress.schedule
      .filter((i) => Boolean(i.date))
      .map((i) => ({
        ...i,
        date: PartnersServices.convertDateFormat(i.date, 'YYYY-MM-DD', 'DD.MM.YYYY'),
        open_time: PartnersServices.convertSecondsToFormat(i.open_time),
        close_time: PartnersServices.convertSecondsToFormat(i.close_time),
      }));
    setHolidays(selectedAddressHolidays);
  }, [selectedAddress]);

  useEffect(() => {
    if (!selectedAddress) return setIsPartnersInputsDirty(true);
    if (!selectedPartner) return;

    // checking if inputs were changed
    const newObj = PartnersServices.convertAddressSavingPayload({
      partnerId: selectedPartner.info.id,
      id: selectedAddress?.id,
      active,
      name,
      address,
      phone,
      weekDays,
      holidays,
    });
    delete newObj.partnerId;
    newObj.schedule = convertSchedule(newObj.schedule);

    const newSelectedAddress = { ...selectedAddress, schedule: convertSchedule(selectedAddress.schedule) };
    delete newSelectedAddress.login;

    // if some state was changed
    setIsPartnersInputsDirty(JSON.stringify(newObj) !== JSON.stringify(newSelectedAddress));
  });

  return {
    values: {
      active,
      name,
      address,
      phone,
      hostessLogin,
      hostessLoginCopied,
      weekDays,
      holidays,
      holidayInputs,
    },
    setters: {
      active: setActive,
      name: setName,
      address: setAddress,
      phone: setPhone,
      weekDays: handleWeekDay,
      holidayInputs: handleHolidayInputs,
    },
    errors: {
      active: activeError,
      name: nameError,
      address: addressError,
      phone: phoneError,
      weekDays: weekDaysError,
      holidayInputs: holidayInputsError,
    },
    events: {
      onClipboardClick,
      onAddHolidayClick,
      onDeleteHolidayClick,
    },
  };
};

export default UseSingleAddressInputs;
