import React from 'react';
import { useAtomValue } from 'jotai/index';
import classNames from 'classnames';
import styles from '../css/index.module.scss';
import WT from '../constants/widgetTranslations';
import MyButton from '../../../components/MyButton';

function WidgetCloseModal({ onCancel, onClose, t }) {
  return (
    <div className={styles.closeModal}>
      <div className={styles.closeModalContent}>
        <p className={styles.closeModalTitle}>{t(WT.cancelBooking)}</p>
      </div>
      <div className={styles.closeModalButtons}>
        <MyButton
          text={t(WT.cancel)}
          secondary
          onClick={onClose}
          className={classNames(styles.myButtonSecondary, styles.closeModalButton)}
          textClassName={styles.myButtonSecondaryText}
        />
        <MyButton
          text={t(WT.continue)}
          onClick={onCancel}
          className={classNames(styles.myButton, styles.closeModalButton)}
        />
      </div>
    </div>
  );
}

export default WidgetCloseModal;
