class LocalStorageServices {
  static getToken = () => localStorage.getItem('token') || '';

  static setToken = (token) => {
    localStorage.setItem('token', token);
  };

  static removeToken = () => {
    localStorage.removeItem('token');
  };

  static getSelectedAddress = () => {
    try {
      return JSON.parse(localStorage.getItem('selectedAddress') || '') || '';
    } catch (e) {
      return '';
    }
  };

  static setSelectedAddress = (address) => {
    localStorage.setItem('selectedAddress', JSON.stringify(address || undefined));
  };

  static removeSelectedAddress = () => {
    localStorage.removeItem('selectedAddress');
  };
}

export default LocalStorageServices;
