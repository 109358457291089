import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './css/index.module.scss';
import MyButton from '../../components/MyButton';
import FormInput from '../../components/FormInput';
import Email from '../../assets/sidebarIcons/email.svg';
import EmailBlue from '../../assets/sidebarIcons/emailBlue.svg';
import EmailRed from '../../assets/sidebarIcons/emailRed.svg';
import Lock from '../../assets/loginIPageIcons/lock.svg';
import LockBlue from '../../assets/loginIPageIcons/lockBlue.svg';
import LockRed from '../../assets/loginIPageIcons/lockRed.svg';
import LogoIcon from '../../assets/images/logo.png';
import TittleIcon from '../../assets/sidebarIcons/reservista.svg';
import sendRequest from '../../helpers/sendRequest';
import Api from '../../api';
import { tokenAtom } from '../../atoms/globalAtoms';
import LocalStorageServices from '../../services/LocalStorageServices';
import useInputState from '../../hooks/UseInputState';

function TokenGenerate() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [serverMessage, setServerMessage] = useState('');

  const generateNewPassword = async () => {
    if (loading) return;

    const data = await sendRequest({
      request: Api.generateNewPassword,
      payload: { token },
      warnErrorText: 'while generating new password',
      setLoading,
    });

    if (data?.status !== 'ok') {
      setServerMessage(data?.message || 'Неизвестная ошибка');
      return;
    }

    setIsSuccess(true);
    if (data?.message) {
      setServerMessage(data.message);
    }
  };

  useEffect(() => {
    if (token?.length) {
      generateNewPassword().then();
    }
  }, [token]);

  const onBackClick = () => {
    navigate('/login');
  };

  return (
    <div className={styles.login_wrapper}>
      <div className={styles.registration_form_div}>
        <form>
          <div className={styles.login_wrapper_logo}>
            <img src={LogoIcon} alt="Reservista" className={styles.login_wrapper_logo_image} />
            <img src={TittleIcon} alt="Reservista" />
          </div>
          <p className={styles.login_wrapper_header}>
            {loading ? 'Подождите...' : serverMessage}
          </p>

          <MyButton
            onClick={onBackClick}
            text="Вернуться"
            className={styles.login_button}
          />
        </form>
      </div>
    </div>
  );
}

export default TokenGenerate;
