import React, { useMemo } from 'react';
import { useAtom } from 'jotai/index';
import moment from 'moment';
import classNames from 'classnames';
import styles from '../css/index.module.scss';
import { widgetInputsAtom, widgetParamsAtom } from '../../../atoms/widgetAtoms';
import useWidget from '../../../hooks/UseWidget';
import WT from '../constants/widgetTranslations';
import EmptyHeight from '../../../components/EmptyHeight';
import PartnersServices from '../../../services/PartnersServices';
import makeFirstUppercase from '../../../helpers/makeFirstUppercase';
import MyButton from '../../../components/MyButton';

function WidgetSuccessState() {
  const { t, closeWidget } = useWidget();
  const [widgetParams] = useAtom(widgetParamsAtom);
  const [widgetInputs] = useAtom(widgetInputsAtom);

  return (
    <>
      <div className={styles.inputsState}>
        <p className={styles.inputsTitle}>{t(WT.successfullyCreated)}</p>
        <EmptyHeight height={24} />
        <p className={styles.text}>
          {makeFirstUppercase(moment(widgetParams.date).format('dddd, DD MMMM'))}
          {' '}
          {PartnersServices.convertSecondsToFormat(widgetInputs.slot)}
          ,
          {' '}
          {widgetParams.persons}
          {' '}
          {t(WT.person, widgetParams.persons)}
        </p>
      </div>
      <div>
        <MyButton
          text={t(WT.close)}
          className={styles.myButton}
          onClick={closeWidget}
        />
      </div>
    </>
  );
}

export default WidgetSuccessState;
