import React, { useEffect } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSearchParams } from 'react-router-dom';
import styles from './css/index.module.scss';
import MyCheckbox from '../../components/MyCheckbox';
import MyButton from '../../components/MyButton';
import MyHorizontalSelect from '../../components/MyHorizontalSelect';
import PlanObjectsTools from './components/PlanObjectsTools';
import PlanBackground from './components/PlanBackground';
import PlanScheme from './components/PlanScheme';
import {
  objectsListAtom, schemeDotsAtom, schemeMiddleDotAtom, selectedObjectAtom,
} from '../../atoms/schemeAtoms';
import PlanObjectsFields from './components/PlanObjectsFields';
import PlanObjects from './components/PlanObjects';
import AddIcon from '../../assets/icons/addGray.svg';
import IconButton from '../../components/IconButton';
import {
  closeModalAtom, isRootAtom, modalStateAtom, openInfoModalAtom,
} from '../../atoms/globalAtoms';
import DeleteHallModal from './components/Modals/DeleteHallModal';
import AddHallModal from './components/Modals/AddHallModal';
import useHalls from '../../hooks/UseHalls';
import {
  gettingHallsLoadingAtom,
  hallsAtom, hallsOptionsAtom, isSelectedHallStateDirtyAtom, popUpedHallAtom,
  selectedHallAtom, updateSelectedHallAtom,
} from '../../atoms/hallsAtoms';
import InfoModal from './components/Modals/InfoModal';
import RenameHallModal from './components/Modals/RenameHallModal';
import PlanServices from '../../services/PlanServices';
import ChangeHallModal from './components/Modals/ChangeHallModal';
import { addressesAtom, selectedAddressAtom } from '../../atoms/accountAtoms';
import sendRequest from '../../helpers/sendRequest';
import Api from '../../api';
import PartnersServices from '../../services/PartnersServices';

function Plan() {
  const {
    getHalls, saveHall, addHall, renameHall, deleteHall, changeSelectedHall,
  } = useHalls();

  const isRoot = useAtomValue(isRootAtom);
  const schemeDots = useAtomValue(schemeDotsAtom);
  const objectsList = useAtomValue(objectsListAtom);
  const schemeMiddleDot = useAtomValue(schemeMiddleDotAtom);

  const selectedObject = useAtomValue(selectedObjectAtom);
  const setModalState = useSetAtom(modalStateAtom);
  const closeModal = useSetAtom(closeModalAtom);

  const halls = useAtomValue(hallsAtom);
  const gettingHallsLoading = useAtomValue(gettingHallsLoadingAtom);

  const updateSelectedHall = useSetAtom(updateSelectedHallAtom);
  const [selectedHall, setSelectedHall] = useAtom(selectedHallAtom);
  const [popUpedHall, setPopUpedHall] = useAtom(popUpedHallAtom);

  const [, setAddresses] = useAtom(addressesAtom);
  const [selectedAddress, setSelectedAddress] = useAtom(selectedAddressAtom);
  const [hallsOptions] = useAtom(hallsOptionsAtom);
  const openInfoModal = useSetAtom(openInfoModalAtom);

  const setIsSelectedHallStateDirty = useSetAtom(isSelectedHallStateDirtyAtom);

  const [searchParams] = useSearchParams();
  const selectedPartnerIdFromQuery = +searchParams.get('selected_partner_id');
  const selectedAddressIdFromQuery = +searchParams.get('selected_address_id');

  const onDelete = async () => {
    if (!popUpedHall) return;
    await deleteHall({ id: popUpedHall.id });
    closeModal();
  };

  const onAdd = async (name) => {
    if (!selectedAddress) return;
    await addHall({ name, address_id: selectedAddress.id });
    closeModal();
  };

  const onRename = async (name) => {
    if (!popUpedHall) return;
    await renameHall({ id: popUpedHall.id, name });
    closeModal();
  };

  const onSaveClick = async (showModalAfter) => {
    if (!selectedHall) return;
    const scheme = PlanServices.convertHallForServer(schemeDots, objectsList, schemeMiddleDot);
    await saveHall({ zones: [{ ...selectedHall, scheme }] });

    if (!showModalAfter) return;
    setModalState({
      isOpen: true,
      children: <InfoModal
        onClose={closeModal}
        text="Помещение было сохранено"
      />,
    });
  };

  const onAddClick = () => {
    if (!hallsOptions.can_create) {
      openInfoModal({ title: 'Нет доступа', text: 'Вы не можете создавать новые помещения по этому адресу' });
      return;
    }
    setModalState({
      isOpen: true,
      children: <AddHallModal
        onClose={closeModal}
        onAdd={onAdd}
      />,
    });
  };

  const onDeleteClick = () => {
    setModalState({
      isOpen: true,
      children: <DeleteHallModal
        onClose={closeModal}
        onDelete={onDelete}
      />,
    });
  };

  const onRenameClick = () => {
    setModalState({
      isOpen: true,
      children: <RenameHallModal
        onClose={closeModal}
        onRename={onRename}
        initName={popUpedHall?.name}
      />,
    });
  };

  const onHallChange = (val) => {
    if (selectedHall?.isUpdated) {
      return setModalState({
        isOpen: true,
        children: <ChangeHallModal
          onGo={() => changeSelectedHall(val)}
          onSave={async () => {
            await onSaveClick(false);
            changeSelectedHall(val);
          }}
        />,
      });
    }
    changeSelectedHall(val);
  };

  useEffect(() => {
    // only for root after opening this page from addresses
    if (selectedPartnerIdFromQuery && selectedAddressIdFromQuery) {
      (async () => {
        const data = await sendRequest({
          request: Api.getAddresses,
          payload: PartnersServices.convertAddressesTableFilters({ items_on_page: null }, selectedPartnerIdFromQuery),
          warnErrorText: 'while getting addresses',
        });

        if (data?.status !== 'ok') return;

        setAddresses(data.data.addresses);
        setSelectedAddress(data.data.addresses.find((i) => i.id === selectedAddressIdFromQuery) || { id: selectedAddressIdFromQuery });
      })();
    }
  }, [selectedAddressIdFromQuery, selectedPartnerIdFromQuery]);

  useEffect(() => {
    if (selectedAddress) {
      getHalls().then();
    }
  }, [selectedAddress]);

  useEffect(() => {
    setIsSelectedHallStateDirty(false);
  }, []);

  return (
    <div className={styles.plan}>

      <div className={styles.header}>
        <div className={styles.header_left}>
          {!!hallsOptions.can_create && (
            <IconButton
              svgPath={AddIcon}
              wrapperClassName={styles.header_btn}
              onClick={onAddClick}
            />
          )}
          <div className={styles.header_left_scroll}>
            {gettingHallsLoading ? <p>Подождите...</p> : (
              <MyHorizontalSelect
                list={halls}
                setSelected={onHallChange}
                selected={selectedHall}
                className={styles.header_left_scroll_inner}
                functionAtRightClick={setPopUpedHall}
                RightClickComponent={(
                  <div className={styles.header_left_list_popup}>
                    <div onClick={onRenameClick} className={styles.header_left_list_popup_item}>
                      Переименовать
                    </div>
                    <div onClick={onDeleteClick} className={styles.header_left_list_popup_item}>Удалить</div>
                  </div>
                    )}
              />
            )}
            <div className={styles.header_left_blur} />
          </div>
        </div>

        {selectedHall && (
        <div className={styles.header_right}>
          <MyCheckbox
            value={selectedHall.active}
            text="Зал активен"
            onClick={() => updateSelectedHall({ active: !selectedHall.active })}
          />
          <MyCheckbox
            value={selectedHall.online_available}
            text="Зал доступен для бронирования онлайн"
            onClick={() => updateSelectedHall({ online_available: !selectedHall.online_available })}
          />
          <MyButton
            className={styles.plan_button}
            text="Сохранить"
            onClick={onSaveClick}
          />
        </div>
        )}
      </div>

      {selectedHall && (
        <div className={styles.main}>

          <div className={styles.tools}>
            <div className={styles.tools_block}>
              <p className={styles.tools_block_title}>Добавить объект</p>
              <PlanObjectsTools />
            </div>
            {selectedObject && (
            <div className={styles.tools_block}>
              <p className={styles.tools_block_title}>Параметры объекта</p>
              <PlanObjectsFields />
            </div>
            )}
          </div>

          <PlanBackground>
            <PlanObjects />
            <PlanScheme />
          </PlanBackground>
        </div>
      )}

    </div>
  );
}

export default Plan;
