function WidgetCloseIcon() {
  const color = getComputedStyle(document.documentElement).getPropertyValue('--widget-background-color');
  const svg = (
    `<svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 19L19 5M5 5L19 19" stroke="${color}" strokeLinecap="round" />
    </svg>`

  );

  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

export default WidgetCloseIcon;
