import React, { useEffect, useMemo } from 'react';
import { useAtom } from 'jotai/index';
import MyHorizontalSelect from '../../../components/MyHorizontalSelect';
import {
  isPartnersInputsDirtyAtom,
  partnersPageStateAtom,
  selectedAddressIdAtom,
  selectedPartnerAddressAtom,
} from '../../../atoms/partnersAtoms';
import { PARTNERS_PAGE_STATES } from '../../../constants/statuses';
import {
  closeModalAtom, modalStateAtom, openConfirmModalAtom, openInfoModalAtom,
} from '../../../atoms/globalAtoms';

function SinglePartnerTabs() {
  const [partnersPageState, setPartnersPageState] = useAtom(partnersPageStateAtom);
  const [selectedPartnerAddress] = useAtom(selectedPartnerAddressAtom);
  const [selectedAddressId] = useAtom(selectedAddressIdAtom);
  const [, openConfirmModal] = useAtom(openConfirmModalAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [isPartnersInputsDirty, setIsPartnersInputsDirty] = useAtom(isPartnersInputsDirtyAtom);

  const data = useMemo(() => (
    partnersPageState === PARTNERS_PAGE_STATES.singlePartnerSingleAddress ? [
      { id: PARTNERS_PAGE_STATES.singlePartnerInfo, name: 'Общая информация' },
      { id: PARTNERS_PAGE_STATES.singlePartnerAddresses, name: 'Адреса' },
      { id: PARTNERS_PAGE_STATES.singlePartnerSingleAddress, name: selectedAddressId === -1 ? 'Новый адрес' : selectedPartnerAddress?.name },
    ] : [
      { id: PARTNERS_PAGE_STATES.singlePartnerInfo, name: 'Общая информация' },
      { id: PARTNERS_PAGE_STATES.singlePartnerAddresses, name: 'Адреса' },
    ]), [partnersPageState, selectedPartnerAddress, selectedAddressId]);

  const onSelect = (val) => {
    if ([
      PARTNERS_PAGE_STATES.singlePartnerInfo,
      PARTNERS_PAGE_STATES.singlePartnerSingleAddress,
    ].includes(partnersPageState) && isPartnersInputsDirty) {
      openConfirmModal({
        title: 'Перейти без сохранения?',
        text: 'Возможно, у вас есть несохранённые изменения, при переходе они будут потеряны',
        buttonText: 'Перейти',
        onButtonClick: () => {
          closeModal();
          setPartnersPageState(val.id);
          setIsPartnersInputsDirty(true);
        },
      });
    } else {
      setPartnersPageState(val.id);
    }
    setIsPartnersInputsDirty(false);
  };

  // useBlocker(() => true, 'You have unsaved changes. Are you sure you want to leave?');

  useEffect(() => {
    setIsPartnersInputsDirty(false);
  }, []);

  return (
    <MyHorizontalSelect
      list={data}
      setSelected={onSelect}
      selected={{ id: partnersPageState }}
    />
  );
}

export default SinglePartnerTabs;
